import { shallowMount } from '@vue/test-utils';
import Start from '@/pages/animal_quiz/start';

jest.mock('vue-meta');
jest.mock('vue-i18n', () => ({
  useI18n: jest.fn(() => ({
    t: () => {},
  })),
}));


describe('AnimalQuizStart.vue', () => {
  it('renders child components', () => {
    const wrapper = shallowMount(Start);

    expect(wrapper.findComponent({ name: 'GameConfiguration' }).exists()).toBe(true);
  });

  it('has a h1', () => {
    const wrapper = shallowMount(Start);

    expect(wrapper.find('h1').exists()).toBe(true);
  });
});
